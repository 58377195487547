import Vue from "vue";
import store from './store';
import vueDebounce from 'vue-debounce'
import messages from './lang/messages';
import App from '../../dist/js/components/CodemediaSearch';

Vue.component('codemedia-search', App);
Vue.use(vueDebounce, {
  fireOnEmpty: false
})

const app = new Vue({
  el: '#app',
  store
});