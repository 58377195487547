<template>
    <div class="go-to-search-results">
        <div class="grid-x">
            <div class="small-12 go-to-search-results-banner">
                <h5>{{ activeLang === 'it-IT' ? 'Vai alla pagina dei risultati di ricerca' : 'View complete serch results'}}</h5>
                <a :href="`/${activeLang.replace('-IT', '').replace('-US','')}/custom-search`" class="button white-transparent-button" @click="storeReferer()">
                    {{ activeLang === 'it-IT' ? 'Vai alla pagina' : 'Visit page'}}
                </a>  
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'go-to-search-results',
        props: [
            'cat'
        ],
        computed: {
            ...mapState({
                searchCategories: state => state.filteredCat,
                searchBrands: state => state.filteredBrand,
                searchString: state => state.searchString.replaceAll('"', '').replaceAll("''", ""),
                activeLang: state => state.currentLanguage,
            }),
        },
        methods: {
            storeReferer() {
                const currentUrl = window.location
                document.cookie = "last_visited_url_before_searchpage="+currentUrl+";path=/"
            }
        }
    }
</script>