<template>
    <transition-group tag="div" name="products" class="results">
        <div class="grid-x"
             v-for="(product, index) in products"
             :key="index * 2">
            <product-tease :product="product"/>
        </div>
        <div class="grid-x search-empty-result" v-if="products.length <= 0" key="empty55">
            <h5>{{ activeLang === 'it-IT' ? 'Nessun prodotto trovato. Prova a modificare la tua ricerca' : 'No products found. Try editing your search'}}</h5>
        </div>
        <GoToSearchResults v-if="products.length" key="goToSearchResults"/>
    </transition-group>
</template>
<script>
import ProductTease from './ProductTease';
import {mapState} from 'vuex'
import GoToSearchResults from './GoToSearchResults'

export default {
    name: 'results',
    components: {ProductTease, GoToSearchResults},
    computed: {
        products() {
            return this.$store.state.filteredProducts
        },
        ...mapState({
            searchCategories: state => state.categories,
            searchBrands: state => state.filteredBrands,
            activeLang: state => state.currentLanguage,
        })
    }
}
</script>
<style scoped lang="scss">
.results {
    position: absolute;
    bottom: -4px;
    left: 0;
    transform: translateY(100%);
    background-color: white;
    width: 100%;
    box-shadow: -4px 4px 28px -8px rgba(0, 0, 0, 0.37);
    max-height: 380px;
    overflow-y: auto;
    border-radius: 4px;
}

.products-enter-active {
    transition: all .8s;
}

.products-enter /* .list-leave-active below version 2.1.8 */
{
    opacity: 0;
    transform: translateY(10px);
}

.go-to-search-results {
    position: sticky;
    bottom: 0;
}

.search-empty-result {

}
.search-empty-result h5 {
    margin-bottom: 0;
    padding: 15px 10px;
    font-size: 14px;
    /* opacity: 0.74; */
    font-weight: 500;
    width: 100%;
    background-color: #36393f;
    color: white;
}
</style>
