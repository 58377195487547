<template>
    <div class="search-cleaner">
        <button v-if="products && ! loading" @click="clearData">
            <i class="fa fa-times-circle"></i>
        </button>
    </div>
</template>
<script>
  export default {
    name: 'cleaner',
    methods: {
      clearData() {
        this.$store.dispatch('clearProducts')
      }
    },
    computed: {
      products() {
        return Object.keys(this.$store.state.products).length
      },
      loading() {
        return this.$store.state.loading
      }
    }
  }
</script>
<style scoped lang="scss">
    .search-cleaner {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        button {
            color: white;
            cursor: pointer;
        }
    }
</style>
