<template>
    <div class="codemedia-search">
        <div class="grid-x">
            <div v-if="!loading" class="cell shrink with-margin show-for-medium">
                <select v-model="filteredCat" id="archive-cat-select">
                    <option v-if="activeLang !== 'it-IT'" :value="null">Categories</option>
                    <option v-else :value="null">Categorie</option>
                    <template v-for="category in searchCategories">
                        <option :value="category.id" v-if="!!category.id && !category.parent">
                            {{ category.name }}
                        </option>
                    </template>
                </select>
            </div>
            <div v-if="!loading" class="cell shrink with-margin show-for-medium">
                <select v-model="filteredBrand" id="archive-brand-select">
                    <option v-if="activeLang !== 'it-IT'" :value="null">Brands</option>
                    <option v-else :value="null">Marche</option>
                    <template v-for="brand in searchBrands">
                        <option :value="brand.id" v-if="!!brand.id">
                            {{ brand.name }}
                        </option>
                    </template>
                </select>
            </div>
            <div class="cell auto">
                <input ref="searchField"
                       :class="{'with-results': searchString.length}"
                       id="archive-search-inputtext"
                       :disabled="loading"
                       v-model="searchString"
                       v-on:keyup="saveData"
                       type="text"
                       :placeholder="loading ? 'Loading...' : 'Search'">
            </div>
        </div>
        <loader/>
        <cleaner v-if="searchString.length || filteredCat || filteredBrand"/>
    </div>
</template>
<script>
import Loader from './Loader'
import Cleaner from './Cleaner'
import {mapState} from 'vuex'

export default {
    name: 'search-input',
    components: {Cleaner, Loader},
    computed: {
        searchString: {
            get() {
                return this.$store.state.searchString
            },
            set(value) {
                if(value.length > 3) {
                    this.$store.dispatch('setString', value)
                }
            }
        },
        timeout: {
            get() {
                return this.$store.state.timeout
            },
            set(value) {
                this.$store.dispatch('setTimeout', value)
            }
        },
        filteredCat: {
            get() {
                return this.$store.state.filteredCat
            },
            set(value) {
                this.$store.dispatch('setFilteredCat', value)
            }
        },
        filteredBrand: {
            get() {
                return this.$store.state.filteredBrand
            },
            set(value) {
                this.$store.dispatch('setFilteredBrand', value)
            }
        },
        activeLang: {
            get() {
                return this.$store.state.currentLanguage
            }
        },
        ...mapState({
            searchCategories: state => state.categories,
            searchBrands: state => state.filteredBrands,
            loading: state => state.loading,
            activeLang: state => state.currentLanguage,
            isSearchPage: state => state.isSearchPage
        })
    },
    methods: {
        saveData(e) {
            const checkTag = document.getElementById('cdm_dt')
            if (!!checkTag &&
                e.key !== 'Shift' &&
                e.key !== 'Control' &&
                e.key !== 'Alt' &&
                e.key !== 'Meta' &&
                this.searchString.length >= 3) {
                    this.$store.dispatch('clearTimeout')

                    this.timeout = setTimeout(() => {
                        this.$store.dispatch('saveData')
                    }, 1000);
                }
        }
    }
}
</script>
<style scoped lang="scss">
.codemedia-search {
    position: relative;
}

input,
select {
    background-color: #2f3238;
    color: white;
    border: none;
    border-radius: 3px;
    border: 1px solid #747679;
    padding: 5px 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.84;

    &.with-results {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:focus {
        background-color: lighten(#575b63, 2%);
    }
}

select {
    padding-right: 30px;
}

.with-margin {
    margin-right: 10px;
}
</style>
