import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {uniqBy} from 'lodash'
import {contains, sortByName} from "./helpers"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        loading: false,
        products: [],
        brands: [],
        categories: [],
        filteredProducts: [],
        filteredBrands: [],
        filteredCat: null,
        filteredBrand: null,
        searchString: '',
        timeout: null,
        currentLanguage: document.getElementsByTagName('html')[0].getAttribute('lang'),
        isSearchPage: document.getElementById('search-page'),
    },
    getters: {},
    mutations: {
        GET_PRODUCTS: (state, products) => state.products = products,
        SET_CATEGORIES: (state, categories) => state.categories = categories,
        SET_BRANDS: (state, brands) => state.brands = brands,
        SET_FILTERED_CAT: (state, cat) => state.filteredCat = cat,
        SET_FILTERED_BRAND: (state, brand) => state.filteredBrand = brand,
        FILTER_BRANDS: (state, filteredBrands) => state.filteredBrands = filteredBrands,
        FILTER_PRODUCTS: (state, filteredProducts) => state.filteredProducts = filteredProducts,
        CLEAR_PRODUCTS: (state) => {
            state.searchString = ''
            state.filteredCat = null
            state.filteredBrand = null
        },
        LOADING: (state, loading) => state.loading = loading,
        SET_STRING: (state, searchString) => state.searchString = searchString,
        SET_TIMEOUT: (state, timeout) => state.timeout = timeout,
    },
    actions: {
        init({commit}) {
            commit('LOADING', true)
            axios.post(`/wp-json/search/v1/initconfig/searchinit`)
                .then(response => {
                    commit('LOADING', false)
                    let products = response.data
                    let itProducts = products.filter(p => p.lang === 'it');
                    let enProducts = products.filter(p => p.lang === 'en');
                    // const activeLang = document.getElementsByTagName('html')[0].getAttribute('lang');

                    if(this.state.currentLanguage == 'it-IT') {
                        products = itProducts;
                    }
                    else {
                        products = enProducts;
                    }

                    const categories = Array.from(new Set(products.map(product => product.category.id)))
                        .map(id => products.find(p => p.category.id === id).category)
                        .sort(sortByName)

                    const brands = (Array.from(new Set(products.map(product => product.brand.id)))
                        .map(id => products.find(p => p.brand.id === id).brand))
                        .sort(sortByName)

                    commit('GET_PRODUCTS', products)
                    commit('SET_CATEGORIES', categories)
                    commit('SET_BRANDS', brands)
                    commit('FILTER_BRANDS', brands)
                })
                .catch( err => {
                    console.log(err)
                })
        },
        clearProducts({commit}) {
            commit('CLEAR_PRODUCTS')
            document.cookie = "last_searched_category=;path=/"
            document.cookie = "last_searched_brand=;path=/"
            document.cookie = "last_searched_string=;path=/"
        },
        setString({commit, dispatch}, searchString) {
            // commit('SET_STRING', searchString.toLowerCase().replaceAll('"', '').replaceAll("'", ""))
            commit('SET_STRING', searchString.toLowerCase())
            dispatch('filterProducts')
            document.cookie = "last_searched_string="+searchString.toLowerCase()+";path=/"
        },
        setFilteredCat({state, commit, dispatch}, cat) {
            commit('SET_FILTERED_CAT', cat)
            commit('SET_FILTERED_BRAND', null)
            dispatch('filterProducts').then(filteredProducts => {
                const filteredBrands = uniqBy(filteredProducts.map(product => product.brand), item => item.id).sort(sortByName)
                commit('FILTER_BRANDS', filteredBrands)
            })
            document.cookie = "last_searched_category="+cat+";path=/"
            document.cookie = "last_searched_brand=;path=/"
        },
        setFilteredBrand({state, commit, dispatch}, brand) {
            commit('SET_FILTERED_BRAND', brand)
            dispatch('filterProducts')
            document.cookie = "last_searched_brand="+brand+";path=/"
        },
        filterProducts({commit, state}) {
            return new Promise((resolve, reject) => {
                let filteredProducts = state.products

                if (!!state.filteredBrand) {
                    filteredProducts = filteredProducts.filter(product => product.brand.id == state.filteredBrand)
                }

                if (!!state.filteredCat) {
                    filteredProducts = filteredProducts.filter(product =>
                        product.category_with_children.some(category => category.term_id == state.filteredCat)
                    )
                }

                if (!!state.searchString) {
                    filteredProducts = filteredProducts.filter(product => {

                        const chunks = state.searchString.split(' ')

                        return contains(product.post_title.toLowerCase(), chunks) ||
                            contains(product._sku && product._sku.toLowerCase(), chunks) ||
                            product.sku_ingram_micro && contains(product.sku_ingram_micro.toLowerCase(), chunks)
                    })
                }
                commit('FILTER_PRODUCTS', filteredProducts)
                resolve(filteredProducts)
            })
        },
        saveData({state}, data = {}) {
            axios.post(`/wp-json/search/v1/searches/create`, {
                params: {
                    value: state.searchString,
                    complete: data.complete,
                    product_id: data.product_id,
                    category_id: state.filteredCat,
                    brand_id: state.filteredBrand,
                }
            })
        },
        setTimeout({commit}, timeout) {
            commit('SET_TIMEOUT', timeout)
        },
        clearTimeout({state}) {
            clearTimeout(state.timeout)
        }
    }
})
