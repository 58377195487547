<template>
    <div v-if="loading" class="search-loader">
        <i class="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
    <div v-else-if="!searchString.length && !filteredCat && !filteredBrand" class="search-loader lens">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"/>
        </svg>
    </div>
</template>
<script>
import {mapState} from 'vuex';

export default {
    name: 'loader',
    computed: mapState({
        loading: state => state.loading,
        searchString: state => state.searchString,
        filteredCat: state => state.filteredCat,
        filteredBrand: state => state.filteredBrand,
    })
}
</script>
<style scoped lang="scss">
.search-loader {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: white;

    &.lens {
        top: 50%;
    }

    svg {
        width: 20px;
    }
}
</style>
