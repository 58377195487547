<template>
    <div class="main">
        <div class="grid-container">
            <div class="grid-x align-center">
                <div class="cell large-8 relative" v-click-outside="closeEvent">
                    <search-input/>
                    <results v-if="searchString.length || filteredCat || filteredBrand" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import SearchInput from './SearchInput'
  import Results from './Results'
  import GoToSearchResults from './GoToSearchResults'
  import {mapState} from 'vuex'

  export default {
    name: 'App',
    components: {Results, SearchInput, GoToSearchResults},
    computed: mapState({
        products: state => state.products,
        searchString: state => state.searchString.replaceAll('"', '').replaceAll("''", ""),
        filteredCat: state => state.filteredCat,
        filteredBrand: state => state.filteredBrand,
    }),
    methods: {
      closeEvent() {
        this.$store.dispatch('clearProducts')
      }
    },
    mounted() {
      this.$store.dispatch('init')
    },
    directives: {
      'click-outside': {
        bind: function(el, binding, vNode) {
          // Provided expression must evaluate to a function.
          if (typeof binding.value !== 'function') {
            const compName = vNode.context.name
            let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
            if (compName) { warn += `Found in component '${compName}'` }

            console.warn(warn)
          }
          // Define Handler and cache it on the element
          const bubble = binding.modifiers.bubble
          const handler = (e) => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
              binding.value(e)
            }
          }
          el.__vueClickOutside__ = handler

          // add Event Listeners
          document.addEventListener('click', handler)
        },

        unbind: function(el, binding) {
          // Remove Event Listeners
          document.removeEventListener('click', el.__vueClickOutside__)
          el.__vueClickOutside__ = null
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .main {
        background-color: #35393f;
        padding: 20px 0;
        z-index: 102;
        position: relative;
    }
    .relative {
        position: relative;
    }
</style>
