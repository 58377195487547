const contains = (target, pattern) => {
    if (!!target) {
        let value = 0;
        pattern.forEach(function(word){
            value = value + target.includes(word);
        });
        return (value === pattern.length)
    }
}

const sortByName = (a, b) => {
    a = !!a.name ? a.name.toLowerCase() : a.name
    b = !!b.name ? b.name.toLowerCase() : b.name
    if (a > b) return 1;
    if (b > a) return -1;

    return 0;
}

export {contains, sortByName}
