<template>
    <a @click.prevent="saveData(product)" :href="product.link" class="cell grid-x grid-padding-x search-tease">
        <div class="cell small-2 align-self-middle">
            <img :src="product.thumbnail.guid" :alt="product.post_title">
        </div>
        <div class="cell align-self-middle small-4">
            {{ product.post_title }}
        </div>
        <div class="cell align-self-middle small-2 search-tease__category">
            {{ product.category.name }}
        </div>
        <div class="cell small-4 align-self-middle text-right large-text-left">
            <!-- <span v-html="product.currency"></span> -->
            {{ calculateDiscountedPrice }}

            <!-- {{ product.category_pricing_rules.tiers[0] }} -->
        </div>
<!--        <tease-banner :product="product"/>-->
    </a>
</template>
<script>
import TeaseBanner from './TeaseBanner';

export default {
    name: 'product-tease',
    components: {TeaseBanner},
    props: {
        product: {}
    },
    computed: {
        displayPrice() {
            return parseFloat(this.product.sale_price) > 0 ?
                this.product.sale_price :
                    this.product.final_price > 0 ?
                    this.product.final_price :
                    this.product.price;
        },
        calculateDiscountedPrice() {
            let best_discount = 0;
            let currentUserRole = document.body.getAttribute('data-logged-in-user-role').toString();
            let price = 0;
            if(parseFloat(this.product.sale_price) > 0) {
                price = this.product.sale_price;
            }
            else {
                price = this.product.price;
            }

            // Foreach price policy, verify if its eligible and apply discount % to product price

            // Loop over all price policies
            if(this.product.category_pricing_rules) {
                let category_pricing_rules = Object.values(this.product.category_pricing_rules);

                category_pricing_rules.forEach((category_pricing_rule) => {
                    if(currentUserRole) {
                        if(category_pricing_rule.roles.includes(currentUserRole)) {
                            this.product.category_with_children.forEach((cat) => {
                                if(category_pricing_rule.categories.includes(cat.term_id)) {
                                    if(parseInt(category_pricing_rule.tier.amount) > parseInt(best_discount)) {
                                        best_discount = category_pricing_rule.tier.amount;
                                    }
                                }
                            })
                        }
                    }
                })
            }

            // Now check single product policies
            if(this.product.singleproduct_pricing_rules) {
                let singleproduct_pricing_rules = Object.values(this.product.singleproduct_pricing_rules);

                singleproduct_pricing_rules.forEach((singleproduct_pricing_rule) => {
                    if(currentUserRole) {
                        if(singleproduct_pricing_rule.singleProductRole == currentUserRole) {
                            if(parseInt(singleproduct_pricing_rule.singleProductAmount) > parseInt(best_discount)) {
                                best_discount = singleproduct_pricing_rule.singleProductAmount;
                            }
                        }
                    }      
                });
            }

            price = parseFloat(price);

            // Finally, calculate price after discount
            if(best_discount > 0) {
                price = (price - (parseInt(price) / 100 * parseInt(best_discount)));
            }

            const formatted_price = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(Math.round(price * 10) / 10);

            best_discount = 0;

            return formatted_price;
        }
    },
    methods: {
        saveData(product) {
            const checkTag = document.getElementById('cdm_dt')
            if (!!checkTag) {
                this.$store.dispatch('clearTimeout')
                this.$store.dispatch('saveData', {
                    complete: 1,
                    product_id: product.id
                })
            }

            window.location.href = product.link
        }
    }
}
</script>

<style scoped lang="scss">
a {
    color: inherit;
}

.search-tease {
    font-size: .9rem;
    border-bottom: 1px solid #eaeaea;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;

    &:hover {
        background-color: #f9f9f9;
    }

    &__category {
        font-weight: 300;
        font-size: .8rem;
        text-transform: uppercase;
        color: #bebebe;
    }

    &__banner {
        position: relative;
    }
}
</style>
