<template>
    <div class="product-tease__banners">
        <div v-if="product.is_on_sale" class="product-tease__onsale-banner">
            <span>Offerta</span>
        </div>
        <div v-if="product.is_on_sale" class="product-tease__percentage-banner">
            <span>{{product.sale_percentage}}%</span>
        </div>
        <div v-if="product.is_recent && ! product.is_on_sale" class="product-tease__recent-banner">
            <span>Novità</span>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'tease-banner',
    props: {
      product: {}
    }
  }
</script>
<style scoped lang="scss">
    .product-tease__banners {
        top: 0;
        right: 0;
        left: auto;
        width: auto;
        font-size: .6rem;
    }
    .product-tease__onsale-banner {
        padding: 2px 8px;
    }
    .product-tease__percentage-banner {
        padding: 2px 8px;
    }

    @media screen and (min-width: 64em) {
        .product-tease__banners {
            top: 0;
            right: 0;
            left: auto;
            width: auto;
            font-size: .8rem;
        }
        .product-tease__onsale-banner {
            padding: 3px 12px;
        }
    }
</style>
